import * as React from 'react';

export const applyProps = <T>(el: HTMLElement, props: T) => {
  if (el && props) {
    Object.entries(props).forEach(
      ([key, value]: [string, unknown]) => (el[key] = value)
    );
  }
};

function applyRef(e: HTMLElement, ref?: (e: HTMLElement) => void) {
  if (ref) {
    ref(e);
  }
}

function ensureRef(props: object, ref?: (e: HTMLElement) => void) {
  return (e: HTMLElement) => {
    if (e) {
      applyProps(e, props);
    }
    applyRef(e, ref);
  };
}

function ensureProps(passedProps: {
  props: object;
  ref: (e: HTMLElement) => void;
}) {
  const { ref, ...pass } = passedProps || { props: {}, ref: undefined };
  const newRef = ensureRef(pass, ref);

  return { ...pass, ref: newRef };
}

export const webComponentsWrapper = (
  createElem: typeof React.createElement
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (type: any, props: any, ...chren: any) => {
    if (typeof type === 'string') {
      props = ensureProps(props);
    }

    return createElem(type, props, ...chren);
  };
};

export default {
  ...React,
  createElement: webComponentsWrapper(React.createElement)
};

import linkedProfilesTooltipEn from '@ac/cross-module-components/dist/components/LinkedProfilesTooltip/i18n/en.json';
import matchingProfileSidePanelEn from '@ac/cross-module-components/dist/components/MatchingProfileSidePanel/i18n/en.json';
import profileBusinessCardEn from '@ac/cross-module-components/dist/components/ProfileBusinessCard/i18n/en.json';
import profileBusinessCardImageEn from '@ac/cross-module-components/dist/components/ProfileBusinessCardImage/i18n/en.json';
import profileImageUploadEn from '@ac/cross-module-components/dist/components/ProfileImageUploadModal/i18n/en.json';
import profileIncognitoEn from '@ac/cross-module-components/dist/components/ProfileIncognito/i18n/en.json';
import i18n from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';

function registerI18nListeners() {
  i18n.on('languageChanged', (lng) => {
    moment.locale(lng);
  });
}

const detection = {
  // order and from where user language should be detected
  order: [
    'querystring',
    'cookie',
    'localStorage',
    'navigator',
    'htmlTag',
    'path',
    'subdomain'
  ],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'] // languages to not persist (cookie, localStorage)
};

registerI18nListeners();

const instance = i18n
  .use(initReactI18next)
  .use(LngDetector)
  .init({
    detection,
    fallbackLng: 'en',
    ns: ['translations'],
    defaultNS: 'translations',
    debug: true,

    react: {
      wait: true
    },

    resources: {
      en: {
        translations: {
          ...matchingProfileSidePanelEn,
          ...profileBusinessCardEn,
          ...linkedProfilesTooltipEn,
          ...profileBusinessCardImageEn,
          ...profileImageUploadEn,
          ...profileIncognitoEn
        }
      }
    }
  });

export default instance;

import { ProfileImageUploadModal } from '@ac/cross-module-components/dist/components/ProfileImageUploadModal/index';
import ClassPropertySnippet from '../Snippets/props/ClassPropertySnippet';
import TestSelectorPropertySnippet from '../Snippets/props/TestSelectorPropertySnippet';
import TFunctionPropertySnippet from '../Snippets/props/TFunctionPropertySnippet';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

interface State {
  isModalOpen: boolean;
  uploadedImagePreview: string;
}

class ProfileImageUploadModalDemo extends React.Component<
  WithTranslation,
  State
> {
  constructor(props: WithTranslation) {
    super(props);

    this.state = {
      isModalOpen: false,
      uploadedImagePreview: ''
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalConfirm = this.handleModalConfirm.bind(this);
  }

  public render() {
    return (
      <div>
        <h1>Profile Image Upload Modal</h1>
        <br />
        <div>
          <h3>Uploaded Image </h3>
          {this.state.uploadedImagePreview ? (
            <img
              src={this.state.uploadedImagePreview || ''}
              width="auto"
              height="300px"
            />
          ) : (
            <p>No image uploaded</p>
          )}
        </div>
        <br />
        <br />
        <ac-button
          onClick={this.handleOpenModal}
          disabled={this.state.isModalOpen}
        >
          Upload image
        </ac-button>
        {this.state.isModalOpen && (
          <ProfileImageUploadModal
            t={this.props.t}
            onClose={this.handleModalClose}
            onConfirm={this.handleModalConfirm}
          />
        )}

        <h3>Interface ProfileImageUploadModalProps:</h3>
        <code>
          class?: boolean;
          <br />
          t: TFunction;
          <br />
          imageIsUpdating?: boolean;
          <br />
          testSelector?: string;
          <br />
          onClose: Function;
          <br />
          onConfirm(file: File): Function;
        </code>

        <h3>Props</h3>

        <h4>onClose</h4>
        <p>Callback on modal close</p>

        <h4>onConfirm</h4>
        <p>callback for save uploaded image with paramter of type File</p>

        <TFunctionPropertySnippet />
        <ClassPropertySnippet />
        <TestSelectorPropertySnippet />
      </div>
    );
  }

  private handleOpenModal() {
    this.setState({ isModalOpen: true });
  }

  private handleModalClose() {
    this.setState({ isModalOpen: false });
  }

  private handleModalConfirm(file: File) {
    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>) => {
      this.setState({
        isModalOpen: false,
        uploadedImagePreview: event.target?.result as string
      });
    };

    reader.readAsDataURL(file);
  }
}

export default withTranslation()(ProfileImageUploadModalDemo);

import { NonIndividualProfileLink } from '@ac/cross-module-components/dist/components/LinkedProfilesTooltip';
import {
  BusinessCardProfile,
  ProfileBusinessCard,
  ProfileBusinessCardSize
} from '@ac/cross-module-components/dist/components/ProfileBusinessCard';
import {
  BaseApi,
  CodeValue,
  CommunicationMode,
  Config,
  ConfigBuilder,
  DictionaryEntity,
  MembershipLevel,
  MembershipType,
  ProfileRole,
  ProfilesDashboardAddressDto,
  ProfilesDashboardCommunicationChannelDto,
  IndividualDashboardDetailsDto
} from '@ac/library-api';
import { AcSelectOption } from '@ac/web-components';
import ClassPropertySnippet from '../Snippets/props/ClassPropertySnippet';
import TestSelectorPropertySnippet from '../Snippets/props/TestSelectorPropertySnippet';
import TFunctionPropertySnippet from '../Snippets/props/TFunctionPropertySnippet';
import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import React from '../wc';

const membershipLevels: MembershipLevel[] = [
  new MembershipLevel({
    code: 'code1',
    color: '#6C301E'
  } as MembershipLevel),
  new MembershipLevel({
    code: 'code2',
    color: '#DAA520'
  } as MembershipLevel)
];

const membershipTypes: MembershipType[] = [
  new MembershipType({
    code: 'code1'
  } as MembershipType),
  new MembershipType({
    code: 'code2'
  } as MembershipType)
];

const contactProfileRoles: DictionaryEntity[] = [
  new DictionaryEntity({
    code: 'BOOKER',
    name: 'Booker'
  }),
  new DictionaryEntity({
    code: 'CONT',
    name: 'Contact Profile'
  }),
  new DictionaryEntity({
    code: 'SITE',
    name: 'Contact on site'
  }),
  new DictionaryEntity({
    code: 'DEC',
    name: 'Decision maker'
  }),
  new DictionaryEntity({
    code: 'MET',
    name: 'Meeting Booker'
  }),
  new DictionaryEntity({
    code: 'BIL',
    name: 'Billing Contact'
  })
];

const memberships = [
  {
    id: '',
    isActive: true,
    levelCode: {
      code: membershipLevels[0].code,
      description: '',
      id: ''
    },
    number: '',
    typeCode: {
      code: membershipTypes[0].code,
      description: '',
      id: ''
    }
  },
  {
    id: '',
    isActive: true,
    levelCode: {
      code: membershipLevels[1].code,
      description: '',
      id: ''
    },
    typeCode: {
      code: membershipTypes[1].code,
      description: '',
      id: ''
    }
  }
];

const individualDetails: IndividualDashboardDetailsDto = {
  titleCode: {
    code: 'Mr',
    description: 'Mr.',
    id: 'individual-details-title-code-id'
  },
  firstName: 'firstName',
  lastName: 'lastName',
  additionalNames: [{ type: 'SecondSurname', value: 'secondSurname' }],
  preferredLanguageProfileDetails: {
    languageCode: { code: 'PL', description: 'PL' },
    localizedIndividualProfileDetails: {
      firstName: 'alternateFirstName',
      lastName: 'alternateLastName',
      title: 'alternateTitle',
      greeting: ''
    }
  },
  vipCode: {
    id: 'vip-code-id',
    code: 'VIP',
    description: 'VIP 1',
    color: '00FF00'
  },
  anniversaries: []
};

const addresses: ProfilesDashboardAddressDto[] = [
  {
    id: '',
    isPrimary: true,
    addressLine1: 'addressLine1',
    addressLine2: 'addressLine2',
    city: 'city',
    postalCode: 'postalCode',
    countryCode: new CodeValue({ code: 'PL', description: 'Poland' })
  }
];

const website: ProfilesDashboardCommunicationChannelDto = {
  id: 'website-id',
  isPrimary: true,
  modeCode: new CodeValue({ code: CommunicationMode.Website }),
  typeCode: undefined,
  details: 'website'
};

const phone: ProfilesDashboardCommunicationChannelDto = {
  id: 'phone-id',
  isPrimary: true,
  modeCode: new CodeValue({ code: CommunicationMode.Phone }),
  typeCode: undefined,
  details: 'Phone'
};

const mobile: ProfilesDashboardCommunicationChannelDto = {
  id: 'mobile-id',
  isPrimary: true,
  modeCode: new CodeValue({ code: CommunicationMode.Mobile }),
  typeCode: undefined,
  details: 'Mobile'
};

const email: ProfilesDashboardCommunicationChannelDto = {
  id: 'email-id',
  isPrimary: true,
  modeCode: new CodeValue({ code: CommunicationMode.Email }),
  typeCode: undefined,
  details: 'Email'
};

const fakeResponse = {
  data: {
    results: {
      MembershipLevel: membershipLevels,
      MembershipType: membershipTypes,
      ContactProfileRole: contactProfileRoles
    },
    pagingParameters: {},
    paging: {}
  }
};

const disabilityCodes = [
  {
    code: 'HEAR'
  },
  {
    code: 'MOB'
  }
];

interface State {
  selectedSize: ProfileBusinessCardSize;
  showAlternateName: boolean;
  showVipCode: boolean;
  showMemberships: boolean;
  showDisabilities: boolean;
  showPhone: boolean;
  showMobile: boolean;
  showEmail: boolean;
  showWebsite: boolean;
  showAddress: boolean;
  isRestricted: boolean;
  isInactivated: boolean;
  textToHighlight: string;
}

interface Option {
  label: string;
  value: ProfileBusinessCardSize;
}

class ProfileBusinessCardDemo extends Component<WithTranslation, State> {
  private options: Option[] = [
    {
      label: 'Large',
      value: ProfileBusinessCardSize.Large
    },
    {
      label: 'Normal',
      value: ProfileBusinessCardSize.Normal
    },
    {
      label: 'Small',
      value: ProfileBusinessCardSize.Small
    },
    {
      label: 'Extra Small',
      value: ProfileBusinessCardSize.ExtraSmall
    }
  ];

  constructor(props: WithTranslation) {
    super(props);

    this.state = {
      selectedSize: this.options[1].value,
      showAlternateName: true,
      showVipCode: true,
      showMemberships: true,
      showDisabilities: true,
      showPhone: true,
      showMobile: true,
      showEmail: true,
      showWebsite: true,
      showAddress: true,
      isRestricted: true,
      isInactivated: true,
      textToHighlight: 'last Poland'
    };

    this.onChange = this.onChange.bind(this);
    this.onSizeChange = this.onSizeChange.bind(this);

    // add fake fetching delay
    BaseApi.defaultConfig = async () =>
      await new Promise<Config>((resolve) =>
        setTimeout(() => resolve(new ConfigBuilder().getConfig()), 1000)
      );

    const mockedCacheKey =
      'AggregatorExtract.1.200.MembershipLevel,MembershipType,ContactProfileRole,GuestStatus,TITLEORDER.|';
    BaseApi['cache'] = {
      [mockedCacheKey]: fakeResponse
    };
  }

  public render() {
    let communicationChannels: ProfilesDashboardCommunicationChannelDto[] = [];
    communicationChannels = this.state.showPhone
      ? [...communicationChannels, phone]
      : communicationChannels;
    communicationChannels = this.state.showMobile
      ? [...communicationChannels, mobile]
      : communicationChannels;
    communicationChannels = this.state.showEmail
      ? [...communicationChannels, email]
      : communicationChannels;
    communicationChannels = this.state.showWebsite
      ? [...communicationChannels, website]
      : communicationChannels;

    const props = {
      profile: {
        id: '1',
        roleCode: new CodeValue({ code: ProfileRole.IND }),
        typeCode: new CodeValue({ code: 'PIND' }),
        status: {
          isActive: !this.state.isInactivated,
          isRestricted: this.state.isRestricted,
          forReview: false,
          isLocked: false
        },
        individualDetails: {
          ...individualDetails,
          preferredLanguageProfileDetails: this.state.showAlternateName
            ? { ...individualDetails.preferredLanguageProfileDetails }
            : undefined,
          vipCode: this.state.showVipCode
            ? individualDetails.vipCode
            : undefined,
          isIndividual: true,
          isCompany: false,
          isTravelAgent: false
        },
        greetings: 'Hello',
        roles: ['BOOKER'],
        addresses: this.state.showAddress ? addresses : [],
        communicationChannels,
        disabilityCodes: this.state.showDisabilities ? disabilityCodes : [],
        linkedProfileIds: [
          {
            profileId: '11',
            profileRoleCode: ProfileRole.CMP
          }
        ],
        memberships: this.state.showMemberships ? memberships : []
      } as BusinessCardProfile,
      linkedProfiles: [
        new NonIndividualProfileLink({
          id: '11',
          fullName: 'Demo company',
          roleCode: ProfileRole.CMP
        })
      ],
      size: this.state.selectedSize,
      contactProfileRoles,
      membershipLevels,
      membershipTypes,
      t: this.props.t,
      textToHighlight: this.state.textToHighlight
    };

    const onChange = (fieldName: keyof State) => (value: unknown) =>
      this.onChange(fieldName, value);

    return (
      <div>
        <h1>Profile Business Card</h1>

        <ac-select
          label="Size"
          optionsArray={this.options}
          optionValueField="value"
          value={this.state.selectedSize}
          optionNameField="label"
          onChangeCallback={this.onSizeChange}
          allowClear={false}
        />
        <br />
        <ac-switch
          label="Show Alternate Name"
          checked={this.state.showAlternateName}
          onChangeCallback={onChange('showAlternateName')}
        />
        <br />
        <ac-switch
          label="Show Vip Code"
          checked={this.state.showVipCode}
          onChangeCallback={onChange('showVipCode')}
        />
        <br />
        <ac-switch
          label="Show Memberships"
          checked={this.state.showMemberships}
          onChangeCallback={onChange('showMemberships')}
        />
        <br />
        <ac-switch
          label="Show Disabilities"
          checked={this.state.showDisabilities}
          onChangeCallback={onChange('showDisabilities')}
        />
        <br />
        <ac-switch
          label="Show Phone"
          checked={this.state.showPhone}
          onChangeCallback={onChange('showPhone')}
        />
        <br />
        <ac-switch
          label="Show Mobile"
          checked={this.state.showMobile}
          onChangeCallback={onChange('showMobile')}
        />
        <br />
        <ac-switch
          label="Show Email"
          checked={this.state.showEmail}
          onChangeCallback={onChange('showEmail')}
        />
        <br />
        <ac-switch
          label="Show Website"
          checked={this.state.showWebsite}
          onChangeCallback={onChange('showWebsite')}
        />
        <br />
        <ac-switch
          label="Show Address"
          checked={this.state.showAddress}
          onChangeCallback={onChange('showAddress')}
        />
        <br />
        <ac-switch
          label="Is Restricted"
          checked={this.state.isRestricted}
          onChangeCallback={onChange('isRestricted')}
        />
        <br />
        <ac-switch
          label="Is Inactivated"
          checked={this.state.isInactivated}
          onChangeCallback={onChange('isInactivated')}
        />
        <br />
        <ac-field
          label="Text to Highlight"
          value={this.state.textToHighlight}
          onInputCallback={onChange('textToHighlight')}
        />

        <ProfileBusinessCard {...props} />

        <h3>Interface ProfileBusinessCardProps:</h3>
        <code>
          profile: {'{'}
          <br />
          &nbsp; id: string;
          <br />
          &nbsp; roleCode: CodeValue;
          <br />
          &nbsp; status: {'{'}
          <br />
          &nbsp;&nbsp; isActive: boolean;
          <br />
          &nbsp;&nbsp; isRestricted?: boolean;
          <br />
          &nbsp; {'}'};<br />
          &nbsp; individualDetails?: IndividualDashboardDetailsDto;
          <br />
          &nbsp; companyDetails?: CompanyDashboardDetailsDto;
          <br />
          &nbsp; travelAgentDetails?: TravelAgentDashboardDetailsDto;
          <br />
          &nbsp; addresses: ProfilesDashboardAddressDto[];
          <br />
          &nbsp; linkedProfiles?: NonIndividualProfileLink[];
          <br />
          &nbsp; communicationChannels:
          ProfilesDashboardCommunicationChannelDto[];
          <br />
          &nbsp; disabilityCodes: CodeValue[];
          <br />
          &nbsp; memberships: Array{'<{'}
          <br />
          &nbsp;&nbsp; id: string;
          <br />
          &nbsp;&nbsp; isActive?: boolean;
          <br />
          &nbsp;&nbsp; levelCode?: CodeValue;
          <br />
          &nbsp;&nbsp; typeCode?: CodeValue;
          <br />
          &nbsp; {'}>'};<br />
          {'}'};<br />
          linkedProfiles?: NonIndividualProfileLink;
          <br />
          size?: ProfileBusinessCardSize;
          <br />
          targetValue?: TargetValueObject;
          <br />
          isTenantOrSubsidiaryContext?: boolean;
          <br />
          zoneId?: string;
          <br />
          businessDate?: string;
          <br />
          onShowProfileClick?: () =&gt; void;
          <br />
          t: TFunction;
          <br />
          accountsReceivables?: {'{'}
          <br />
          &nbsp; isVisible: boolean;
          <br />
          &nbsp; isFetching: boolean;
          <br />
          &nbsp; collection: ARAccountDashboardItem[];
          <br />
          {'}'};
          <br />
          negotiatedRates?: {'{'}
          <br />
          &nbsp; isVisible: boolean;
          <br />
          &nbsp; isFetching: boolean;
          <br />
          &nbsp; collection: NegotiatedRate[];
          <br />
          {'}'};
          <br />
          displayExtendedMembershipContent?: boolean;
        </code>

        <h3>Props</h3>

        <h4>profile</h4>
        <p>information about profile</p>

        <h4>linkedProfiles</h4>
        <p>Company/Ta profiles that User is a link</p>

        <h4>size</h4>
        <p>Size of business card</p>

        <h4>isTenantOrSubsidiaryContext</h4>
        <p>
          forces fetching profiles or other entities from endpoints that do not
          require a property header
        </p>

        <h4>zoneId</h4>
        <p>
          allows to fetch entities for tenant or for subsidiary when subsidiary
          header is not set
        </p>

        <h4>textToHighlight</h4>
        <p>
          Currently applicable only for small business card. Highlighting passed
          words.
        </p>

        <h4>businessDate</h4>
        <p>
          Currently applicable only for small business card. Current business
          date to proper filter negotiated rates displayed on profile tile
        </p>

        <h4>negotiatedRates</h4>
        <p>
          Currently applicable only for small business card. Allow to pass
          options related to enable negotiated rates functionality on profile
          tile.
          <br />
          Available options: <br />
        </p>
        <ul>
          <li>isVisible - allow to display negotiated rate code, </li>
          <li>isFetching - skeleton will be displayed, </li>
          <li>collection - list of negotiated rates assigned to profile </li>
        </ul>

        <h4>accountsReceivable</h4>
        <p>
          Currently applicable only for small business card. Allow to pass
          options related to enable accounts receivable functionality on profile
          tile.
          <br />
          Available options: <br />
        </p>
        <ul>
          <li>isVisible - allow to display accounts receivable number, </li>
          <li>isFetching - skeleton will be displayed, </li>
          <li>
            collection - list of accounts receivables assigned to profile{' '}
          </li>
        </ul>

        <h4>displayExtendedMembershipContent</h4>
        <p>Show extended membership content in badges</p>

        <TFunctionPropertySnippet />
        <ClassPropertySnippet />
        <TestSelectorPropertySnippet />
      </div>
    );
  }

  private onChange(fieldName: keyof State, value: unknown) {
    this.setState({ [fieldName]: value } as Pick<State, keyof State>);
  }

  private onSizeChange(
    selectedOption: AcSelectOption<ProfileBusinessCardSize>
  ) {
    this.setState({
      ...this.state,
      selectedSize: selectedOption.value as ProfileBusinessCardSize
    });
  }
}

export default withTranslation()(ProfileBusinessCardDemo);

import React, { Component } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Main from './DemoLayout/Main';
import Sidebar from './DemoLayout/Sidebar';

export default class App extends Component {
  public render() {
    return (
      <DndProvider backend={HTML5Backend}>
        <div className="cmc-wrapper">
          <Sidebar />
          <Main />
        </div>
      </DndProvider>
    );
  }
}

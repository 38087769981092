import { CodeValue, GuestStatus, GuestStatusRole } from '@ac/library-api';
import ClassPropertySnippet from '../Snippets/props/ClassPropertySnippet';
import TestSelectorPropertySnippet from '../Snippets/props/TestSelectorPropertySnippet';
import * as React from 'react';
import { ProfileSpecialNeeds } from '@ac/cross-module-components/dist/components/ProfileSpecialNeeds';

const guestStatus: GuestStatus[] = [
  new GuestStatus({
    code: 'code1',
    color: '#07fa02',
    isActive: true,
    role: GuestStatusRole.DIS
  } as GuestStatus),
  new GuestStatus({
    code: 'code2',
    isActive: true,
    role: GuestStatusRole.DIS
  } as GuestStatus)
];

const disabilityCodes = [
  {
    code: 'code1',
    description: 'Code1'
  },
  {
    code: 'code2',
    description: 'Code2'
  }
] as CodeValue[];

export default class ProfileSpecialNeedsDemo extends React.Component {
  public render() {
    return (
      <div>
        <h1>Profile Special Needs</h1>

        <ProfileSpecialNeeds
          disabilityCodes={disabilityCodes}
          guestStatus={guestStatus}
        />

        <h3>Interface ProfileSpecialNeeds:</h3>
        <code>
          disabilityCodes: CodeValue[]; <br />
          guestStatus?: GuestStatus[];
          <br />
          class?: string;
          <br />
          testSelector?: string;
        </code>

        <h3>Props</h3>

        <h4>disabilityCodes</h4>
        <p>disabilityCodes from profiles</p>

        <h4>guestStatus</h4>
        <p>
          GuestStatus from Configuration if guestStatus undefined the default
          background color of the badge will be red
        </p>

        <ClassPropertySnippet />

        <TestSelectorPropertySnippet />
      </div>
    );
  }
}

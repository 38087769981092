import { NotesIndicator } from '@ac/cross-module-components/dist/components/NotesIndicator';
import * as React from 'react';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';

const getCodeWithCount = (count: number) =>
  `<NotesIndicator notesCount={${count}} />`;
const notesIndicatorProps = `interface NotesIndicatorProps {
  notesCount: number;
}`;

export default class NotesIndicatorDemo extends React.Component {
  public render() {
    return (
      <div>
        <h1>Notes Indicator</h1>
        <h3>Basic usage</h3>
        <section>
          <SyntaxHighlighter language="tsx" style={darcula}>
            {getCodeWithCount(0)}
          </SyntaxHighlighter>
          <NotesIndicator notesCount={0} />
          <br />
          <SyntaxHighlighter language="tsx" style={darcula}>
            {getCodeWithCount(1)}
          </SyntaxHighlighter>
          <NotesIndicator notesCount={1} />
        </section>
        <h3>Interface INotesIndicatorProps:</h3>
        <SyntaxHighlighter language="tsx" style={darcula}>
          {notesIndicatorProps}
        </SyntaxHighlighter>
      </div>
    );
  }
}

import {
  LinkedProfilesTooltip,
  NonIndividualProfileLink
} from '@ac/cross-module-components/dist/components/LinkedProfilesTooltip';
import { ProfileRole } from '@ac/library-api';
import { AcSelectOption } from '@ac/web-components';
import ClassPropertySnippet from '../Snippets/props/ClassPropertySnippet';
import TestSelectorPropertySnippet from '../Snippets/props/TestSelectorPropertySnippet';
import TFunctionPropertySnippet from '../Snippets/props/TFunctionPropertySnippet';
import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import React from '../wc';

interface State {
  linkedProfiles: NonIndividualProfileLink[];
  selectedProfileRole: ProfileRole;
  nonIndividualProfileName: string;
  switchPrimary: boolean;
}

interface Option {
  label: string;
  value: ProfileRole;
}

class LinkedProfilesTooltipDemo extends Component<WithTranslation, State> {
  private options: Option[] = [
    {
      label: 'Company',
      value: ProfileRole.CMP
    },
    {
      label: 'Travel Agent',
      value: ProfileRole.TA
    }
  ];

  constructor(props: WithTranslation) {
    super(props);

    this.state = {
      selectedProfileRole: this.options[0].value,
      nonIndividualProfileName: '',
      switchPrimary: false,
      linkedProfiles: [
        new NonIndividualProfileLink({
          id: '1',
          fullName: 'Demo company',
          roleCode: ProfileRole.CMP
        })
      ]
    };

    this.handleSwitchPrimary = this.handleSwitchPrimary.bind(this);
    this.handleAddProfile = this.handleAddProfile.bind(this);
    this.handleRemoveProfile = this.handleRemoveProfile.bind(this);
    this.onRoleChange = this.onRoleChange.bind(this);
    this.onNonIndividualProfileNameChange =
      this.onNonIndividualProfileNameChange.bind(this);
  }

  public render() {
    const { linkedProfiles, nonIndividualProfileName } = this.state;

    return (
      <div>
        <h1>Linked Profiles Tooltip</h1>

        <ac-select
          label="Profile Role"
          optionsArray={this.options}
          optionValueField="value"
          value={this.state.selectedProfileRole}
          optionNameField="label"
          onChangeCallback={this.onRoleChange}
          allowClear={false}
          required={true}
        />

        <ac-field
          label="Company/Travel Agency Name"
          value={this.state.nonIndividualProfileName}
          onInputCallback={this.onNonIndividualProfileNameChange}
        />

        <ac-button
          disabled={
            linkedProfiles.length > 3 || nonIndividualProfileName === ''
          }
          onClick={this.handleAddProfile}
        >
          Add profile
        </ac-button>

        <ac-button
          class="ac-spacing-left-md"
          disabled={linkedProfiles.length === 1}
          onClick={this.handleRemoveProfile}
        >
          Remove profile
        </ac-button>
        <br />
        <br />
        <ac-switch
          label="Switch primary"
          checked={this.state.switchPrimary}
          disabled={linkedProfiles.length === 1}
          onChangeCallback={this.handleSwitchPrimary}
        />

        <br />
        <br />

        <ac-text>Result </ac-text>

        <LinkedProfilesTooltip
          linkedProfiles={linkedProfiles}
          t={this.props.t}
        />
        <h3>Interface LinkedProfilesTooltipProps:</h3>
        <code>
          linkedProfiles: NonIndividualProfileLink[]; <br />
          class?: string; <br />
          t: TFunction; testSelector?: string; <br />
        </code>

        <h3>Props</h3>

        <h4>linkedProfiles</h4>
        <p>Company/Ta profiles that User is a link</p>

        <ClassPropertySnippet />

        <TFunctionPropertySnippet />

        <TestSelectorPropertySnippet />
      </div>
    );
  }

  private handleSwitchPrimary = () => {
    const { linkedProfiles } = this.state;

    [linkedProfiles[0], linkedProfiles[1]] = [
      linkedProfiles[1],
      linkedProfiles[0]
    ];
    this.setState((prevState) => ({
      switchPrimary: !prevState.switchPrimary,
      linkedProfiles
    }));
  };

  private handleRemoveProfile = () => {
    const { linkedProfiles } = this.state;
    linkedProfiles.splice(linkedProfiles.length - 1, 1);
    this.setState({
      ...this.state,
      linkedProfiles
    });
  };

  private handleAddProfile = () => {
    const {
      linkedProfiles,
      nonIndividualProfileName,
      selectedProfileRole: profileRole
    } = this.state;
    const newProfile = new NonIndividualProfileLink({
      fullName: nonIndividualProfileName,
      id: (linkedProfiles.length + 1).toString(),
      roleCode: profileRole as ProfileRole.CMP | ProfileRole.TA
    });

    this.setState({
      selectedProfileRole: this.options[0].value,
      nonIndividualProfileName: '',
      linkedProfiles: linkedProfiles.concat([newProfile])
    });
  };

  private onRoleChange(profileRole: AcSelectOption<ProfileRole>) {
    if (profileRole?.value) {
      this.setState({
        selectedProfileRole: profileRole.value
      });
    }
  }

  private onNonIndividualProfileNameChange(value: string) {
    this.setState({
      nonIndividualProfileName: value || ''
    });
  }
}

export default withTranslation()(LinkedProfilesTooltipDemo);

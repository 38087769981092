import { BusinessCardProfile } from '@ac/cross-module-components/dist/components/ProfileBusinessCard/index';
import { ProfileBusinessCardImage } from '@ac/cross-module-components/dist/components/ProfileBusinessCardImage/index';
import {
  CodeValue,
  ProfileRole,
  IndividualDashboardDetailsDto
} from '@ac/library-api';
import ClassPropertySnippet from '../Snippets/props/ClassPropertySnippet';
import TestSelectorPropertySnippet from '../Snippets/props/TestSelectorPropertySnippet';
import TFunctionPropertySnippet from '../Snippets/props/TFunctionPropertySnippet';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

const individualDetails: IndividualDashboardDetailsDto = {
  titleCode: { id: 'id', code: 'Mr', description: 'Mr.' },
  firstName: 'firstName',
  lastName: 'lastName'
};

class ProfileBusinessCardImageDemo extends React.Component<WithTranslation> {
  constructor(props: WithTranslation) {
    super(props);
  }

  public render() {
    const profile: BusinessCardProfile = {
      id: '1',
      roleCode: new CodeValue({ code: ProfileRole.CMP }),
      typeCode: new CodeValue({ code: 'PIND' }),
      status: {
        isActive: true,
        isRestricted: false,
        forReview: false,
        isLocked: false
      },
      individualDetails: {
        ...individualDetails
      },
      roles: ['BOOKER'],
      addresses: [],
      communicationChannels: [],
      disabilityCodes: [],
      memberships: []
    };

    return (
      <div>
        <h1>Profile Business Card Image</h1>

        <ProfileBusinessCardImage profile={profile} t={this.props.t} />

        <h3>Interface ProfileBusinessCardImageProps:</h3>
        <code>
          profile: BusinessCardProfile;
          <br />
          class?: boolean;
          <br />
          t: TFunction;
          <br />
          testSelector: string;
          <br />
          targetValue?: TargetValueObject;
        </code>

        <h3>Props</h3>

        <h4>profile</h4>
        <p>Profile to be displayed</p>

        <TFunctionPropertySnippet />
        <ClassPropertySnippet />
        <TestSelectorPropertySnippet />
      </div>
    );
  }
}

export default withTranslation()(ProfileBusinessCardImageDemo);

import * as React from 'react';

const ClassPropertySnippet = () => {
  return (
    <div>
      <h4>class</h4>
      <p>Passing a string to `class` will add string to class attribute of component.</p>
    </div>
  );
};

export default ClassPropertySnippet;

import {
  MatchingProfileSidePanel,
  MatchingProfileMode
} from '@ac/cross-module-components/dist/components/MatchingProfileSidePanel';
import { BusinessCardProfile } from '@ac/cross-module-components/dist/components/ProfileBusinessCard/businessCardProfile';
import { BaseApi, ProfileRole } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations/config';
import ClassPropertySnippet from '../Snippets/props/ClassPropertySnippet';
import TestSelectorPropertySnippet from '../Snippets/props/TestSelectorPropertySnippet';
import TFunctionPropertySnippet from '../Snippets/props/TFunctionPropertySnippet';
import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import React from '../wc';
import { AcSelectOption } from '@ac/web-components';

interface State {
  isShown: boolean;
  hideCreateProfile: boolean;
  allowToClosePanelIfNoProfilesFound: boolean;
  showQueryField: boolean;
  query: string | undefined;
  match: string | undefined;
  mode?: MatchingProfileMode;
  role?: ProfileRole;
}

class MatchingProfileSidePanelDemo extends Component<WithTranslation, State> {
  constructor(props: WithTranslation) {
    super(props);

    this.state = {
      isShown: true,
      allowToClosePanelIfNoProfilesFound: false,
      hideCreateProfile: false,
      showQueryField: false,
      role: undefined,
      query: 'Biznesu',
      match: 'details.firstName:janusz|details.lastName:biznesu',
      mode: MatchingProfileMode.query
    };

    BaseApi.host = acConfig.apiUrl;
  }

  public render() {
    const props = {
      query: this.state.query,
      match: this.state.match,
      mode: this.state.mode,
      profileRole: this.state.role,
      isShown: this.state.isShown,
      allowToClosePanelIfNoProfilesFound:
        this.state.allowToClosePanelIfNoProfilesFound,
      hideCreateProfile: this.state.hideCreateProfile,
      showQueryField: this.state.showQueryField,
      onProfileSelect: (profile: BusinessCardProfile) => {
        alert(`profile ID: ${profile.id}`);
        // eslint-disable-next-line no-console
        console.log('profile', profile);
      },
      onSidePanelClose: () => alert('onSidePanelClose'),
      t: this.props.t
    };

    const onChange = (fieldName: keyof State) => (value: unknown) =>
      this.onChange(fieldName, value);

    const onSelectChange =
      (fieldName: keyof State) => (selectedOption: AcSelectOption) =>
        this.onChange(fieldName, selectedOption?.value);

    return (
      <div>
        <h1>Matching Profile Side Panel</h1>

        <ac-switch
          label="Is Shown"
          checked={this.state.isShown}
          onChangeCallback={onChange('isShown')}
        />
        <br />
        <ac-switch
          label="Hide Create Profile Button"
          checked={this.state.hideCreateProfile}
          onChangeCallback={onChange('hideCreateProfile')}
        />
        <br />
        <ac-switch
          label="Allow To Close Panel If No Profiles Found"
          checked={this.state.allowToClosePanelIfNoProfilesFound}
          onChangeCallback={onChange('allowToClosePanelIfNoProfilesFound')}
        />
        <br />
        <ac-box sizeSm={4}>
          <ac-switch
            label="Show query field"
            checked={this.state.showQueryField}
            onChangeCallback={onChange('showQueryField')}
          />
          <ac-field
            label="Query (on demo you got always the same results)"
            value={this.state.query}
            onInputCallback={onChange('query')}
          />
        </ac-box>
        <br />
        <ac-box sizeSm={4}>
          <ac-field
            label="Match (on demo you got always the same results)"
            value={this.state.match}
            onInputCallback={onChange('match')}
          />
        </ac-box>
        <br />
        <ac-box sizeSm={4}>
          <ac-select
            label="Mode"
            optionsArray={Object.entries(MatchingProfileMode).map(
              ([label, value]) => ({ label, value })
            )}
            optionValueField="value"
            value={this.state.mode}
            optionNameField="label"
            onChangeCallback={onSelectChange('mode')}
          />
        </ac-box>
        <br />
        <ac-box sizeSm={4}>
          <ac-select
            label="Profile role"
            optionsArray={Object.entries(ProfileRole).map(([label, value]) => ({
              label,
              value
            }))}
            optionValueField="value"
            value={this.state.role}
            optionNameField="label"
            onChangeCallback={onSelectChange('role')}
          />
        </ac-box>
        <br />

        <MatchingProfileSidePanel {...props} />

        <h3>Interface MatchingProfileSidePanelProps:</h3>
        <code>
          isShown: boolean;
          <br />
          mode?: MatchingProfileMode;
          <br />
          query?: string;
          <br />
          match?: string;
          <br />
          profileRole: ProfileRole | undefined;
          <br />
          layer?: SidePanelLayer;
          <br />
          hideCreateProfile?: boolean;
          <br />
          showQueryField?: boolean;
          <br />
          isTenantOrSubsidiaryContext?: boolean;
          <br />
          isFiltersButtonHidden?: boolean;
          <br />
          zoneId?: string;
          <br />
          businessDate?: string
          <br />
          onProfileSelect: (profile: GeneralProfileDashboardItem) =&gt; void;
          <br />
          onSidePanelClose?: () =&gt; void;
          <br />
          profileIdsToExclude? string[];
          <br />
          excludeProfilesFrozenByIntegration?: boolean;
          <br />
          allowToClosePanelIfNoProfilesFound?: boolean;
          <br />
          customFilters?: string[];
          <br />
          t: TFunction;
          <br />
          class?: string;
          <br />
          testSelector?: string;
        </code>

        <h3>Props</h3>

        <h4>isShown</h4>
        <p>hide or show side panel</p>

        <h4>mode</h4>
        <p>based on mode, different api will be used</p>

        <h4>query</h4>
        <p>query passed to API</p>

        <h4>match</h4>
        <p>match passed to API</p>

        <h4>profileRole</h4>
        <p>
          profile role passed to API and it impact on create new profile button
          link
        </p>

        <h4>layer</h4>
        <p>side panel layer</p>

        <h4>hideCreateProfile</h4>
        <p>hide message and button below the list</p>

        <h4>showQueryField</h4>
        <p>
          enables query input field which enables query manipulating internally
          (only for query and find mode)
        </p>

        <h4>isTenantOrSubsidiaryContext</h4>
        <p>
          forces fetching profiles or other entities from endpoints that do not
          require a property header
        </p>

        <h4>isFiltersButtonHidden</h4>
        <p>allows to hide filters button inside the header of the panel</p>

        <h4>zoneId</h4>
        <p>
          allows to fetch entities for tenant or for subsidiary when subsidiary
          header is not set
        </p>

        <p>
          forces fetching profiles from endpoint that does not require a
          property header
        </p>

        <h4>businessDate</h4>
        <p>
          current business date to proper filter negotiated rates displayed on
          profile tile
        </p>

        <h4>onProfileSelect</h4>
        <p>called when user click profile</p>

        <h4>onSidePanelClose</h4>
        <p>called when user click close side panel button</p>

        <h4>profileIdsToExclude</h4>
        <p>
          profile ids passed to API. Works only when you specify profile role.
        </p>

        <h4>excludeProfilesFrozenByIntegration</h4>
        <p>additional filters for frozen will be added to endpoints</p>

        <h4>allowToClosePanelIfNoProfilesFound</h4>
        <p>it is possible to close panel if no profiles found</p>

        <h4>customFilters</h4>
        <p>it is possible to add custom filters to profile endpoints</p>

        <TFunctionPropertySnippet />
        <ClassPropertySnippet />
        <TestSelectorPropertySnippet />
      </div>
    );
  }

  private onChange = (fieldName: keyof State, value: unknown) => {
    this.setState({ [fieldName]: value } as Pick<State, keyof State>);
  };
}

export default withTranslation()(MatchingProfileSidePanelDemo);

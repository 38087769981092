import * as React from 'react';

const TFunctionPropertySnippet = () => {
  return (
    <div>
      <h4>t</h4>
      <p>TFunction object from &apos;i18next&apos; library</p>
    </div>
  );
};

export default TFunctionPropertySnippet;

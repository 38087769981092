import ClassPropertySnippet from '../Snippets/props/ClassPropertySnippet';
import TestSelectorPropertySnippet from '../Snippets/props/TestSelectorPropertySnippet';
import * as React from 'react';

interface State {
  isShown: boolean;
}

class NewProfilePanelDemo extends React.Component<
  Record<string, unknown>,
  State
> {
  public render() {
    return (
      <div>
        <h1>New Profile Panel</h1>
        Renders new-profile-panel component from Reservation module
        <h3>Interface NewProfilePanelProps:</h3>
        <code>
          isShown: boolean;
          <br />
          profileRole: ProfileRole;
          <br />
          layer?: SidePanelLayer;
          <br />
          onCreate: (profile: {'{'} id: string {'}'}) =&gt; void;
          <br />
          onCancel?: () =&gt; void;
          <br />
          class?: string;
          <br />
          testSelector?: string;
        </code>
        <h3>Props</h3>
        <h4>isShown</h4>
        <p>hide or show side panel</p>
        <h4>profileRole</h4>
        <p>profileRole</p>
        <h4>layer</h4>
        <p>side panel layer</p>
        <h4>onCreate</h4>
        <p>callback when profile was created successfully</p>
        <h4>onCancel</h4>
        <p>callback when user click Cancel button</p>
        <ClassPropertySnippet />
        <TestSelectorPropertySnippet />
      </div>
    );
  }
}

export default NewProfilePanelDemo;

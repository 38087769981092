import { ProfileAvatar } from '@ac/cross-module-components/dist/components/ProfileAvatar';
import { ProfileRole } from '@ac/library-api';
import { AcSelectOption } from '@ac/web-components';
import ClassPropertySnippet from '../Snippets/props/ClassPropertySnippet';
import { Component } from 'react';
import React from '../wc';

interface State {
  selectedProfileRole: ProfileRole;
  isDisabled: boolean;
  firstName: string;
  lastName: string;
}

interface Option {
  label: string;
  value: ProfileRole;
}

export default class ProfileAvatarDemo extends Component<
  Record<string, unknown>,
  State
> {
  private options: Option[] = [
    {
      label: 'Company',
      value: ProfileRole.CMP
    },
    {
      label: 'Individual',
      value: ProfileRole.IND
    },
    {
      label: 'Travel Agent',
      value: ProfileRole.TA
    }
  ];

  constructor(props: Record<string, unknown>) {
    super(props);

    this.state = {
      selectedProfileRole: this.options[1].value,
      isDisabled: false,
      firstName: 'Grzegorz ',
      lastName: 'Brzęczyszczykiewicz'
    };

    this.onRoleChange = this.onRoleChange.bind(this);
    this.onDisabledChange = this.onDisabledChange.bind(this);
    this.onFirstNameChange = this.onFirstNameChange.bind(this);
    this.onLastNameChange = this.onLastNameChange.bind(this);
  }

  public render() {
    return (
      <div>
        <h1>Profile Avatar</h1>

        <ac-select
          label="Profile Role"
          optionsArray={this.options}
          optionValueField="value"
          value={this.state.selectedProfileRole}
          optionNameField="label"
          onChangeCallback={this.onRoleChange}
          allowClear={false}
        />

        <ac-field
          label="First Name"
          value={this.state.firstName}
          onInputCallback={this.onFirstNameChange}
        />

        <ac-field
          label="Last Name"
          value={this.state.lastName}
          onInputCallback={this.onLastNameChange}
        />

        <ac-switch
          label="Is Disabled"
          checked={this.state.isDisabled}
          onChangeCallback={this.onDisabledChange}
        />

        <br />

        <ac-text>Result </ac-text>

        <ProfileAvatar
          profileRole={this.state.selectedProfileRole}
          isDisabled={this.state.isDisabled}
          individualProfileDetails={{
            firstName: this.state.firstName,
            lastName: this.state.lastName
          }}
        />

        <h3>Interface ProfileAvatarProps:</h3>
        <code>
          profileRole: ProfileRole; <br />
          individualProfileDetails?: {'{'}
          <br />
          &nbsp; firstName: string; <br />
          &nbsp; lastName: string; <br />
          {'}'}; <br />
          class?: string; <br />
          isDisabled?: boolean; <br />
        </code>

        <h3>Props</h3>

        <h4>profileRole</h4>
        <p>One of profile roles: [{Object.values(ProfileRole).join(', ')}]</p>

        <h4>individualProfileDetails</h4>
        <p>
          To pass first and last name for individual profile. Applicable when
          <code>profileRole == ProfileRole.IND && isDisabled != true</code>
        </p>

        <ClassPropertySnippet />

        <h4>isDisabled</h4>
        <p>
          Applicable when <code>profileRole == ProfileRole.IND</code>
        </p>
      </div>
    );
  }

  private onRoleChange(profileRole: AcSelectOption<ProfileRole>) {
    this.setState({
      selectedProfileRole: profileRole.value as ProfileRole
    });
  }

  private onDisabledChange(enabled: boolean) {
    this.setState({
      isDisabled: enabled
    });
  }

  private onFirstNameChange(value: string) {
    this.setState({
      firstName: value || ''
    });
  }

  private onLastNameChange(value: string) {
    this.setState({
      lastName: value || ''
    });
  }
}

import * as React from 'react';

class Home extends React.Component {
  public render() {
    return (
      <div>
        <h1>Welcome to cross-module components library!</h1>
        <h4>Todo: Add readme</h4>
      </div>
    );
  }
}

export default Home;

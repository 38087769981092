import { ProfileMemberships } from '@ac/cross-module-components/dist/components/ProfileMemberships';
import { MembershipLevel, MembershipType } from '@ac/library-api';
import ClassPropertySnippet from '../Snippets/props/ClassPropertySnippet';
import TestSelectorPropertySnippet from '../Snippets/props/TestSelectorPropertySnippet';
import * as React from 'react';

const membershipLevels: MembershipLevel[] = [
  new MembershipLevel({
    code: 'code1',
    color: '#6C301E'
  } as MembershipLevel),
  new MembershipLevel({
    code: 'code2',
    color: '#DAA520'
  } as MembershipLevel)
];

const membershipTypes: MembershipType[] = [
  new MembershipType({
    code: 'code1'
  } as MembershipType),
  new MembershipType({
    code: 'code2'
  } as MembershipType)
];

const memberships = [
  {
    id: '',
    isActive: true,
    levelCode: {
      code: membershipLevels[0].code,
      description: '',
      id: ''
    },
    number: '944824',
    typeCode: {
      code: membershipTypes[0].code,
      description: '',
      id: ''
    }
  },
  {
    id: '',
    isActive: true,
    levelCode: {
      code: membershipLevels[1].code,
      description: '',
      id: ''
    },
    number: '98df528',
    typeCode: {
      code: membershipTypes[1].code,
      description: '',
      id: ''
    }
  },
  {
    id: '',
    isActive: false,
    levelCode: {
      code: membershipLevels[1].code,
      description: '',
      id: ''
    },
    number: '999999',
    typeCode: {
      code: membershipTypes[1].code,
      description: '',
      id: ''
    }
  }
];

export default class ProfileMembershipsDemo extends React.Component {
  public render() {
    return (
      <div>
        <h1>Profile Memberships</h1>

        <ProfileMemberships
          membershipLevels={membershipLevels}
          membershipTypes={membershipTypes}
          memberships={memberships}
        />

        <ProfileMemberships
          membershipLevels={membershipLevels}
          membershipTypes={membershipTypes}
          memberships={memberships}
          large={true}
        />

        <h3>Interface ProfileMembershipsProps:</h3>
        <code>
          membershipLevels: MembershipLevel[]; <br />
          membershipTypes: MembershipType[];
          <br />
          memberships: Array{'<{'}
          <br />
          &nbsp;&nbsp; id: string;
          <br />
          &nbsp; isActive?: boolean;
          <br />
          &nbsp; levelCode?: CodeValue;
          <br />
          &nbsp; typeCode?: CodeValue;
          <br />
          {'}>'};<br />
          class?: string;
          <br />
          testSelector?: string;
          <br />
          large?: boolean;
        </code>

        <h3>Props</h3>

        <h4>membershipLevels</h4>
        <p>Memberships Levels from Configuration</p>

        <h4>membershipTypes</h4>
        <p>Memberships Types from Configuration</p>

        <h4>memberships</h4>
        <p>Memberships assigned to profile</p>

        <h4>large</h4>
        <p>Show extended membership content in badges</p>

        <ClassPropertySnippet />

        <TestSelectorPropertySnippet />
      </div>
    );
  }
}

import * as React from 'react';
import { Link } from 'react-router-dom';

import pkg from '../../package.json';
const ADDON_COMPONENTS_VERSION =
  pkg.dependencies['@ac/cross-module-components'];

class Sidebar extends React.Component {
  public render() {
    return (
      <aside className="cmc-aside">
        <nav>
          <ul className="cmc-nav-ul">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/matching-profile-side-panel">
                Matching Profile Side Panel
              </Link>
            </li>
            <li>
              <Link to="/new-profile-panel">New Profile Panel</Link>
            </li>
            <li>
              <Link to="/profile-avatar">Profile Avatar</Link>
            </li>
            <li>
              <Link to="/profile-business-card">Profile Business Card</Link>
            </li>
            <li>
              <Link to="/profile-business-card-image">
                Profile Business Card Image
              </Link>
            </li>
            <li>
              <Link to="/profile-image-upload-modal">
                Profile Image Upload Modal
              </Link>
            </li>
            <li>
              <Link to="/linked-profiles-tooltip">Linked Profiles Tooltip</Link>
            </li>
            <li>
              <Link to="/profile-memberships">Profile Memberships</Link>
            </li>
            <li>
              <Link to="/profile-special-needs">Profile Special Needs</Link>
            </li>
            <li>
              <Link to="/profile-incognito">Profile Incognito</Link>
            </li>
            <li>
              <Link to="/notesIndicator">Notes Indicator</Link>
            </li>
          </ul>
        </nav>
        <footer>version: {ADDON_COMPONENTS_VERSION}</footer>
      </aside>
    );
  }
}

export default Sidebar;

import { defineCustomElements } from '@ac/web-components/dist/loader';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import tsx from 'react-syntax-highlighter/dist/esm/languages/prism/tsx';
import 'react-syntax-highlighter/dist/esm/styles/prism/prism';
SyntaxHighlighter.registerLanguage('tsx', tsx);

import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './i18n';
import registerServiceWorker from './registerServiceWorker';

import '@ac/cross-module-components/dist/components/LinkedProfilesTooltip/index.css';
import '@ac/cross-module-components/dist/components/MatchingProfileSidePanel/index.css';
import '@ac/cross-module-components/dist/components/ProfileBusinessCard/index.css';
import '@ac/cross-module-components/dist/components/ProfileBusinessCardImage/index.css';
import '@ac/cross-module-components/dist/components/ProfileImageUploadModal/index.css';
import '@ac/web-components/dist/web-components/web-components.css';
import './index.css';

defineCustomElements(window);

const container = document.querySelector('#root');
const root = createRoot(container as Element);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

registerServiceWorker();
defineCustomElements(window);

import * as React from 'react';

import {
  ProfileIncognitoBadge,
  ProfileIncognitoInfotip
} from '@ac/cross-module-components/dist/components/ProfileIncognito';
import { WithTranslation, withTranslation } from 'react-i18next';

import ClassPropertySnippet from '../Snippets/props/ClassPropertySnippet';
import TFunctionPropertySnippet from '../Snippets/props/TFunctionPropertySnippet';
import TestSelectorPropertySnippet from '../Snippets/props/TestSelectorPropertySnippet';

type ProfileIncognitoDemoProps = WithTranslation;

class ProfileIncognitoDemo extends React.Component<ProfileIncognitoDemoProps> {
  public render() {
    const useTranslation = (key: string) => this.props.i18n.t(key);

    return (
      <div>
        <h1>Profile Incognito</h1>

        <h2>ProfileIncognitoInfotip</h2>

        <ProfileIncognitoInfotip
          incognitoDetails={{ firstName: 'Janusz', lastName: 'Biznesu' }}
          t={useTranslation}
        />

        <h3>Interface ProfileIncognitoInfotip:</h3>
        <code>
          incognitoDetails: {'{'}
          <br />
          &nbsp; firstName?: string; <br />
          &nbsp; lastName?: string; <br />
          {'}'}; <br />
          testSelector?: string; <br />
          targetValue: TargetValueObject; <br />
          class?: string; <br />
          disabled?: boolean; <br />
          t: TFunction; <br />
        </code>

        <h3>Props</h3>

        <h4>incognitoDetails</h4>
        <p>Incognito details from model</p>

        <TestSelectorPropertySnippet />

        <h4>targetValue</h4>
        <p>Allows to pass target name which allows setting proper class</p>

        <ClassPropertySnippet />

        <h4>disabled</h4>
        <p>Allows to disable infotip - turns off all actions</p>

        <TFunctionPropertySnippet />

        <h2>ProfileIncognitoBadge</h2>

        <ProfileIncognitoBadge t={useTranslation} />

        <h3>Interface ProfileIncognitoBadge:</h3>
        <code>
          id?: string; <br />
          class?: string; <br />
          testSelector?: string; <br />
          t: TFunction; <br />
        </code>

        <h3>Props</h3>

        <h4>id</h4>
        <p>Allows to pass id to component</p>

        <ClassPropertySnippet />

        <TestSelectorPropertySnippet />

        <TFunctionPropertySnippet />
      </div>
    );
  }
}

export default withTranslation()(ProfileIncognitoDemo);

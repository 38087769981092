import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import LinkedProfilesTooltipDemo from '../Components/LinkedProfilesTooltipDemo';
import ProfileBusinessCardImageDemo from '../Components/ProfileBusinessCardImageDemo';
import ProfileImageUploadModalDemo from '../Components/ProfileImageUploadModalDemo';
import ProfileIncognitoDemo from '../Components/ProfileIncognitoDemo';
import MatchingProfileSidePanelDemo from '../Components/MatchingProfileSidePanelDemo';
import NewProfilePanelDemo from '../Components/NewProfilePanelDemo';
import NotesIndicatorDemo from '../Components/NotesIndicatorDemo';
import ProfileAvatarDemo from '../Components/ProfileAvatarDemo';
import ProfileBusinessCardDemo from '../Components/ProfileBusinessCardDemo';
import ProfileMembershipsDemo from '../Components/ProfileMembershipsDemo';
import Home from './Home';
import ProfileSpecialNeedsDemo from '../Components/ProfileSpecialNeedsDemo';

class Main extends React.Component {
  public render() {
    return (
      <main className="cmc-main">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/matching-profile-side-panel"
            element={<MatchingProfileSidePanelDemo />}
          />
          <Route path="/new-profile-panel" element={<NewProfilePanelDemo />} />
          <Route path="/profile-avatar" element={<ProfileAvatarDemo />} />
          <Route
            path="/profile-business-card"
            element={<ProfileBusinessCardDemo />}
          />
          <Route
            path="/linked-profiles-tooltip"
            element={<LinkedProfilesTooltipDemo />}
          />
          <Route
            path="/profile-memberships"
            element={<ProfileMembershipsDemo />}
          />
          <Route
            path="/profile-special-needs"
            element={<ProfileSpecialNeedsDemo />}
          />
          <Route
            path="/profile-business-card-image"
            element={<ProfileBusinessCardImageDemo />}
          />
          <Route
            path="/profile-image-upload-modal"
            element={<ProfileImageUploadModalDemo />}
          />
          <Route path="/profile-incognito" element={<ProfileIncognitoDemo />} />
          <Route path="/notesIndicator" element={<NotesIndicatorDemo />} />
        </Routes>
      </main>
    );
  }
}

export default Main;
